<template>
  <div class="headerBox">
    <div class="centerBox">
      <div class="headerLeft">
        <div class="logoBox">
          <img src="@/assets/imgIcon/headerIcon/logo.svg" alt="" />
        </div>
        <div class="navList">
          <div
            class="navItem"
            v-for="(item, index) in navList"
            :key="index"
            :class="{ navItemActive: index == headerActive }"
            @click="checkNav(item)"
          >
            {{ item.name }}
            <div class="navTag" v-if="index == headerActive"></div>
          </div>
        </div>
      </div>
      <div class="headerRight">
        <div class="history iconBox" @click="$gotoUrl('/user')">
          <img src="@/assets/imgIcon/headerIcon/history.svg" alt="" />
        </div>
        <el-dropdown trigger="hover">
          <div class="userIcon iconBox">
            <img src="@/assets/imgIcon/headerIcon/user.svg" alt="" />
          </div>
          <el-dropdown-menu slot="dropdown" style="min-width: 100px">
            <el-dropdown-item>
              <div @click="handleLogout">退出登录</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { moduleList } from "@/api/enPlat";
export default {
  props: ["headerActive"],
  data() {
    return {
      navList: [
        { name: "首页", ename: "default", path: "/" },
        // { name: "英语", ename: "english", path: "/english" },
        // { name: "科研", ename: "science", path: "" },
        // { name: "电子商务", ename: "tec", path: "" },
        // { name: "法律", ename: "law", path: "" },
      ],
    };
  },
  mounted() {},
  methods: {
    // 退出
    handleLogout() {
      console.log("用户退出登录");
      this.$store.dispatch("loginOut");
      this.$router.push("/loginIndex");
    },
    // 切换导航
    checkNav(item) {
      // this.$emit('update:headerActive',item.ename)
      if (!item.path) {
        this.$fixTip({
          message: "暂未开放",
          type: "waring",
        });
        return;
      }
      if (item.moduleId) {
        this.$emit("update:headerActive", item.navHeaderIndex);
        this.$store.commit("setmoduleId", item.moduleId);
      }
      this.$gotoUrl(item.path);
    },
    getModelList(id) {
      moduleList({
        school_id: id,
        token: this.$store.state.token,
      }).then((res) => {
        if (res.code == 200) {
          if (this.$store.state.userinfo.identity != "student") {
            this.navList.push({
              name: "班级管理",
              ename: "classList",
              path: "/classList",
            });
          }
          let index = 2;
          if(this.$store.state.userinfo.identity == 'student'){
            index=1
          }
          res.data.forEach((item) => {
            this.navList.push({
              name: item.name,
              ename: item.ename,
              path: "/studyPlat?id=" + item._id+'&index='+index,
              moduleId: item._id,
              navHeaderIndex: index,
            });
            index++;
          });
        } else {
          console.log("获取moduleList列表报错：", res.data);
        }
      });
    },
  },
  watch: {
    "$store.state.userinfo": {
      handler(val, oldVal) {
        if ((val, val.school_id)) {
          this.getModelList(val.school_id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
.headerBox {
  background: #3864a1;
  width: 100%;
  height: 68px;
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  .centerBox {
    .headerLeft {
      display: flex;
      align-items: center;
      height: 47px;

      .logoBox {
        padding-right: 47px;
        border-right: 1px solid #fff;
        height: 100%;
        img {
          max-height: 100%;
          max-width: 166px;
        }
      }
      .navList {
        display: flex;
        align-items: center;
        border-right: 1px solid #fff;
        height: 100%;
        .navItem {
          display: flex;
          justify-content: center;
          margin: 0 25px;
          color: #d6d6d6;
          cursor: pointer;
          position: relative;
          font-size: 14px;
          .navTag {
            position: absolute;
            bottom: -22px;
            width: 100%;
            height: 7px;
            border-radius: 15px 15px 0 0;
            background: #1a009c;
          }
        }
        .navItem:hover {
          color: #fff;
        }
        .navItemActive {
          color: #fff;
          font-weight: 600;
          font-size: 16px;
        }
      }
    }
    .headerRight {
      display: flex;
      align-items: center;
      .iconBox {
        cursor: pointer;

        img {
          width: 24px;
          padding: 0 15px;
        }
      }
    }
  }
}
</style>