import $store from '@/store/index.js'
import { getModel } from '@/api/other.js'
export async function getModelList() {
    let params = { types: 'edu' }
    if ($store.state.token) {
        params.token = $store.state.token
    }
    await getModel(params).then(res => {
        if (res.code == 200) {
            if (res.data.length) {
                modelType = res.data
            }
            $store.commit('setModelList', modelType)
        }
    })
}

export let modelType = [
    {
        name: "deepseek",
        flow_model: "/ymqy_model4_flow",
        model_version: "deepseek-chat",
        code: 14,
        record: '网信算备330105747635301240017号 '
    },
    {
        name: "星火3.0",
        code: 5,
        flow_model: "/ymqy_model2_flow",
        model_version: "V3.0",
        record: '网信算备340104764864601230021号'
    },
    {
        name: "文心3.5",
        code: 8,
        flow_model: "/ymqy_model5_flow",
        model_version: "ERNIE-Bot 4.0",
        record: '网信算备110108645502801230043号'
    },
]

export const selectedValue = {
    getName() {
        let model_version = ""
        modelType.filter(item => {
            if (item.model_version != $store.state.models.model_version) {
                model_version = 'V3.0'
            } else {
                model_version = $store.state.models.model_version
                return
            }
        })
        return model_version
    }
}
export const flow_model = $store.state.models.flow_model
export const model_version = $store.state.models.model_version
export const modelName = $store.state.models.name
export const modelRecord = $store.state.models.record
var checkModel = {
    getModelType(version) {
        console.log("传进来的模型：", version)
        let flow_model;
        let model_version;
        let modelName;
        let record
        modelType.filter(item => {
            if (item.model_version == version) {
                flow_model = item.flow_model
                model_version = item.model_version
                modelName = item.name
                record = item.record
            }
        })
        // console.log('匹配到的模型：',flow_model, model_version, modelName)
        if (flow_model && model_version) {
            return { flow_model, model_version, modelName, record }
        } else {
            // 没有则返回默认模型
            let arr = modelType.filter(item => item.code == 5)
            if (!arr.length) arr = modelType.filter(item => item.code == 14)
            return arr[0]
        }
    }
}
export default checkModel