// 代码高亮插件
import hljs from 'highlight.js';

export  const high = {
  deep: true,
  bind: function (el, binding) {
    const targets = el.querySelectorAll('code')
    targets.forEach((target) => {
      if (binding.value) {
        target.textContent = binding.value;
      }
      hljs.highlightBlock(target);
    })
  },
  componentUpdated: function (el, binding) {
    const targets = el.querySelectorAll('code')
    targets.forEach((target) => {
      if (binding.value) {
        target.textContent = binding.value;
        hljs.highlightBlock(target);
      }
    })
  }
}
