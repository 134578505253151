<template>
  <div id="learnPlat" ref="learnPlat">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import { getModelList } from "@/utils/model";
export default {
  name: "App",
  components: {},
  data() {
    return {
      isRouterAlive: true,
    };
  },
  async mounted() {
    await getModelList();
   
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
        this.$store.commit("setRouterAlive", true);
      });
    },
   
  },
  watch: {
    "$store.state.setRouterAlive": {
      handler(val, oldVal) {
        console.log("app中isRouterAlive状态：", val, oldVal);
        if (!val) {
          this.reload();
        }
      },
    },
  },
};
</script>

<style>
#learnPlat {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 1200px;
  /* min-width: 1450px; */
}
/*滚动条宽高 */
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* 滚动条上的滚动滑块。样式 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.2);
}

/* 鼠标悬停时，设置滑块的背景颜色为深灰色 */
::-webkit-scrollbar-thumb:hover {
  background-color: #3864a1;
}

/* 鼠标按下时，设置滑块的背景颜色为灰色 */
::-webkit-scrollbar-thumb:active {
  background-color: #c6dbf5;
}
</style>
