import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from "@/store/index"
import { authTokenFun } from "@/utils/common/baseFun"
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)

}
VueRouter.prototype.replace = function replace(location) {
    // if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
}
const routes = [
    // test路由
    {
        path: '/comTest',
        name: 'comTest',
        component: () => import('@/views/testFile/comTest.vue')
    },
    // 404
    {
        path: '/error',
        name: 'error',
        component: () => import('@/views/error/index.vue')
    },
    // 登录页面
    {
        path: '/loginIndex',
        name: 'loginIndex',
        component: () => import('@/views/login/loginIndex.vue')
    },
    // 用户协议页面
    {
        path: '/userAgreement',
        name: 'userAgreement',
        component: () => import('@/views/protocol/userAgreement.vue')
    },
    // 用户隐私协议
    {
        path: '/userPrivacy',
        name: 'userPrivacy',
        component: () => import('@/views/protocol/userPrivacy.vue')
    },
    // 首页
    {
        path: '/',
        name: 'default',
        component: () => import('@/views/defaultPage/index.vue')
    },
    {
        path: '/classList',
        name: 'classList',
        component: () => import('@/views/classList/index.vue')
    },
    {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/modifyInform.vue')
    },
    // 英语类目
    {
        path: '/studyPlat',
        name: 'studyPlat',
        component: () => import('@/views/enPlat/index.vue')
    },
]
const router = new VueRouter({
    routes
})
router.beforeEach(async (to, from, next) => {
    next()
    // let tokenOutTime = $store.state.tokenOutTime //token过期时间
    // console.log('tokenOutTime',tokenOutTime)
    // if (!tokenOutTime || Date.now() >= tokenOutTime) {
    //     console.log('token过期,route退出登录')
    //     await authTokenFun()
    // }
    var token = $store.state.token
    // console.log('token：', token,$store.state.userinfo)
    // 如果你去的路径不是登录页
    if (to.path != '/loginIndex') {
        // console.log('登录：', to.path, token)
        // 做登录判断
        if (token) {
            next()
        } else if (
            to.name == 'comTest' ||
            to.name == 'error' ||
            to.name == 'userAgreement' ||
            to.name == 'userPrivacy'
        ) {
            console.log('无需登录页面：', to.path)
            next()
        } else {
            Vue.prototype.$message.error('请先登录')
            //没登录，打回登录页
            next('/loginIndex') //放行到login页面
        }
    } else {
        console.log('不用做登录校验：', to.path)
        next()
    }
})
// 路由守卫，用于处理未找到的路由
router.onError((error) => {
    console.log("路由导航报错：", error);
    const { type } = error;
    if (type === 2) {
        router.push('/error'); // 如果是2，表示是一个导航守卫错误，可以重定向到首页
    }
});

export default router