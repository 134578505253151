import Vue from 'vue';
import $store from '@/store/index';
import { authToken } from "@/api/auth"
// 跳转
function gotoUrl(url) {
    this.$router.push(url);
}
Vue.prototype.$gotoUrl = gotoUrl;


// 验证token是否夫妻
export async function authTokenFun() {
    let token = $store.state.token;
    console.log('authToken', token)
    if (token) {
        await authToken({ token: token }).then(res => {
            if (res.code == 200) {
                return true;
            } else if (res.code == 301) {
                $store.dispatch('loginOut')
                return false;
            } else {
                return false;
            }
        })
    } else {
        $store.dispatch('loginOut')
        return false;
    }
}