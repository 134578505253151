import fixTip from '@/components/borderTip/fixTip.vue'

export default function (Vue) {
    const Constructor = Vue.extend(fixTip)
    const Instance = new Constructor()
    Instance.$mount()
    document.body.appendChild(Instance.$el)
    Vue.prototype.$fixTip = (content) => {
        Instance.show = true
        Instance.content = content
        return new Promise((res, rej) => {
            // Instance.confirm = function () {
            //     res()
            //     Instance.show = false
            // }
            // Instance.close = function () {
            //     rej()
            //     Instance.show = false
            // }
        })
    }
}

