import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import createPersistedState from 'vuex-persistedstate'
import { secureLocalStorage, secureSessionStorage } from '@/utils/secure-storage'
Vue.use(Vuex)

// 数据本地化
export const localStorageData = createPersistedState({
    key: 'userinfo',
    // paths: ['userinfo'],// 指定要持久化的路径
    storage: {
        getItem: key => secureLocalStorage.getItem(key),
        setItem: (key, value) => secureLocalStorage.setItem(key, value),
        removeItem: key => secureLocalStorage.removeItem(key)
    },
    // reducer: state => ({
    //     userinfo: state.userinfo
    // }) //存储指定数据
})
export const sessionStorageData = createPersistedState({
    key: 'sessionInfo',
    // paths: ['permission'],
    storage: {
        getItem: key => secureSessionStorage.getItem(key),
        setItem: (key, value) => secureSessionStorage.setItem(key, value),
        removeItem: key => secureSessionStorage.removeItem(key)
    }
    // reducer: state => ({})
})
const store = new Vuex.Store({
    state: {
        token: "",
        tokenOutTime: "",
        userinfo: {},
        moduleId: '',//模块id,英语，电子商务...
        isRouterAlive: true,
        waitRobotContent: null,//停靠的机器人内容
        robotHandlerMark: null,//Object类型 机器人事件设置，单纯前端操作事件
        wsUserSayText: '',
        wsForTextToAudio: null, //ws文字转语音
        wsAudioToText: null,//ws语音转文字
        wsReadStatus: true,
        wsAudioSecond: 60,
        wsAudioFilecode: '',
        models: {
            name: "星火3.5",
            flow_model: "/ymqy_model2_flow",
            model_version: "V3.5.0"
        },
        modelList: [],
        temporaryMarkWordId:'',//用于临时标记，点击单词变色
        handlerText:'',//事件触发的文本
        oldHandlerText:false,//记录本次触发事件文本是否和上次一致
        modelList:[],//模型列表
        newTriggerItem:null,//需要新添加的触发事件的item,类型为对象则添加，为string则删除
        robotSicContent:null,//非流式结果，其他页面传递给robot交互区输出内容
    },
    getters: {},
    mutations: {
        setRobotSicContent(state, newVal){
            state.robotSicContent = newVal;
        },
        setNewTriggerItem(state, newVal){
            state.newTriggerItem = newVal
        },
        setModelList(state, newVal){
            state.modelList = newVal
        },
        setHandlerText(state, newVal) {
            if(state.handlerText == newVal){
                state.oldHandlerText = true
            }else{
                state.oldHandlerText = false
            }
            state.handlerText = newVal
        },
        setTemporaryMarkWordId(state, newVal) {
            state.temporaryMarkWordId = newVal
        },
        setModelList(state, newVal) {
            state.modelList = newVal
        },
        setModels(state, newVal) {
            state.models = newVal
        },
        setwsForTextToAudio(state, newVal) {
            state.wsForTextToAudio = newVal
        },
        setwsAudioToText(state, newVal) {
            state.wsAudioToText = newVal
        },
        setwsReadStatus(state, newVal) {
            state.wsReadStatus = newVal
        },
        setwsAudioSecond(state, newVal) {
            state.wsAudioSecond = newVal
        },
        setwsAudioFilecode(state, newVal) {
            state.wsAudioFilecode = newVal
        },
        setWsUserSayText(state, newVal) {
            state.wsUserSayText = newVal
          },
        setRobotHandlerMark(state, newVal) {
            state.robotHandlerMark = newVal
            // console.log(state.robotHandlerMark)
        },
        setWaitRobotContent(state, newVal) {
            state.waitRobotContent = newVal
        },
        clearState(state) {
            // 重置状态
            state.token = "";
            state.tokenOutTime = ""
            state.userinfo = {};
            state.moduleId = ''
        },
        setRouterAlive(state, newVal) {
            console.log("setRouterAlive", newVal)
            state.isRouterAlive = newVal
        },
        SET_TOKEN(state, newVal) {
            if (newVal) {
                let time = new Date().getTime() + 1000 * 60 * 60 * 24
                state.tokenOutTime = time
            }
            state.token = newVal
        },
        setUserInfo(state, newVal) {
            state.userinfo = newVal
        },
        setmoduleId(state, newVal) {
            state.moduleId = newVal
        }
    },
    actions: {
        loginOut(context) {
            context.commit('clearState')
            context.commit('setRouterAlive', false)
            // console.log("store中检测退出状态", context)
        }
    },
    modules: {},
    // 将插件配置到Vuex的plugins中
    // plugins: [
    //     createPersistedState({
    //         key: 'user_info',
    //     })
    // ]
    plugins: [localStorageData, sessionStorageData]
    // plugins: [localStorageData]
})
export default store