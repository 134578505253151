import request from '@/utils/request';
import axios from 'axios';
// 模型查询
export function getModel(params) {
    return request({
      method: 'get',
      url: '/v2/dm/chat/get_model',
      params
    })
  }
  // 语种人物形象选择
export function getAlternatives(data) {
  return request({
    url: '/v2/media/xf/alternatives',
    method: 'get',
    data
  })
}
// 文字转语音
export function getAudio(data) {
  let { file_code, token, language, character, text, message_id } = data
  // console.log(data)
  return request({
    responseType: 'blob',
    url: '/v2/media/xf/text/audio',
    method: 'post',
    params: {
      file_code, token, message_id
    },
    data: {
      language, character, text
    },
  })
}
// 语种人物形象选择
export function getTip(data) {
  console.log("getTip", data);
  return request({
    url: '/v2/interact/get/tip',
    method: 'get',
    params: {
      ...data
    }
  })
}
//获取用户对应的话题列表
export function getTopcis(data) {
  let { token, mode } = data
  console.log("获取历史：", token, mode)
  return request({
    url: '/v2/interact/get/topics',
    method: 'get',
    params: {
      token,
      mode
    }
  })
}
export function getEvaluateIse(data) {
  console.log(data)
  let { file_code, language, category, group, ise_text, token, _id } = data
  return request({
    url: '/v2/media/xf/audio/ise',
    method: 'post',
    params: {
      file_code, language, category, group, ise_text, token, _id
    },
  })
}
// 查询人物形象
export function getFigure(params) {
  return request({
    url: '/v2/user/get_figure',
    method: 'get',
    params
  })
}
// 控制请求中断
export function getAudio2(data, that) {
  let { file_code, token, language, character, text, message_id } = data
  // console.log(data)
  return request({
    responseType: 'blob',
    url: '/v2/media/xf/text/audio',
    method: 'post',
    params: {
      file_code, token, message_id
    },
    data: {
      language, character, text
    },
    cancelToken: new axios.CancelToken(function executor(c) {
      // 设置 cancel token
      that.source = c
    })
  })
}
// 语音测评音频播放
export function getIseAudioPlay(data) {
  return request({
    url: '/v2/interact/get/xinyu_audio',
    method: 'get',
    params: data
  })
}
// 获取模型配置
export function modelConfige(params){
  return request({
      url:"/v2/interact/get/get_model_configure",
      method:"get",
      params
  })
}
// 评分报告
export function scoringReports(params) {
  return request({
    url: '/v2/media/xf/scoring_reports',
    method: 'post',
    params
  })
}