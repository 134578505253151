<template>
  <div>
    <transition name="fade">
      <div class="popContainer" @click="close" v-show="show">
        <div class="fixTip">
          <p>
            <span
              class="el-icon-success"
              style="color: #5cb87a"
              v-show="content.type == 'success'"
            ></span>
            <span
              class="el-icon-warning"
              style="color: #8896b3"
              v-show="content.type == 'warning'"
            ></span>
            <span
              class="el-icon-error"
              style="color: #f56c6c"
              v-show="content.type == 'error'"
            ></span>
            {{ content.message }}
          </p>
          <img src="@/assets/imgs/imageLogo.png" alt="" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "fixTip",
  data() {
    return {
      show: false,
      content: {
        message: "这是一条提示信息",
        type: "warring",
      },
    };
  },
  methods: {
    close() {
      this.$nextTick(() => {
        this.show = false;
      });
    },
  },
  watch: {
    show(val) {
      if (val) {
        setTimeout(() => {
          this.$nextTick(() => {
            this.show = false;
          });
        }, 2000);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.popContainer {
  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
}
.fixTip {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border: 1px solid #ccc;
  background: #fcfcfc;
  border-radius: 5px;
  box-shadow: 0px 0px 8px 0px rgba(93, 138, 207, 0.5);
  color: #333;
  padding: 10px 100px 10px 10px;
  display: flex;
  align-items: center;
  text-align: center;
  img {
    width: 110px;
    height: 110px;
    position: absolute;
    bottom: -20px;
    right: -30px;
    z-index: 9999;
  }
  p {
    width: max-content;
    font-size: 16px;
    margin: 0;
    line-height: 30px;
    display: flex;
    align-items: center;
    span {
      color: red;
      font-size: 30px;
      margin: 0 5px;
    }
  }
}
.fade-enter-active {
  animation: fade-in 0.5s;
}
.fade-leave-active {
  animation: fade-in 0.5s reverse;
}
@keyframes fade-in {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@media screen and (max-width: 768px) {
  .fixTip {
    max-width: 90%;
    text-align: left;
    p {
      line-height: normal;
      font-size: 14px;
    }
  }
}
</style>
