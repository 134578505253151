import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import 'echarts'
import ECharts from 'vue-echarts'
Vue.component('ECharts', ECharts)

import fixTip from '@/utils/fixTip'
Vue.use(fixTip)

import removeAria from "@/utils/directive/removeAria";
//全局注册
Vue.directive("removeAria", removeAria);

Vue.config.productionTip = false

import HeaderCom from "@/components/header/index"
Vue.component("HeaderCom", HeaderCom)


// import 'highlight.js/styles/base16/solarized-light.css';
import { high } from '@/utils/directive/height-light.js'
Vue.directive('highlight', high)

import 'animate.css';

import './assets/css/base.css'
import "@/assets/icon/iconfont.css"

import '@/utils/common/baseFun'


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
