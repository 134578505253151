import request from '@/utils/request';
import axios from 'axios';

// 查询每日一题
export function getDayQuestion(params) {
  return request({
    url: '/v2/role/get_day_question',
    params,
    method: 'get'
  })
}

// 核验阅读理解
export function checkQuestion(params, data) {
  return request({
    data,
    params,
    method: 'post',
    url: '/v2/study/manager/check_question'
  })
}

// 加入单词本
export function addWordBook(params) {
  return request({
    params,
    url: '/v2/role/add_word_book',
    method: 'get'
  })
}
// 单词本
export function wordBook(params) {
  return request({
    params,
    url: '/v2/role/word_book',
    method: 'get'
  })
}
// 删除单词本
export function delWordBook(params) {
  return request({
    url: "/v2/role/del_word_book",
    method: 'get',
    params
  })
}

// 提交写作题
export function checkWriting(params, data) {
  return request({
    method: 'post',
    params,
    data,
    url: '/v2/role/check_writng'
  })
}
// 获取ai提示
export function getAiTip(params) {
  return request({
    params,
    url: '/v2/study/manager/get_ai_tip',
    method: 'get'
  })
}
// 获取AI例文
export function getAiEg(params) {
  return request({
    params,
    url: '/v2/study/manager/get_ai_eg',
    method: 'get'
  })
}
// 获取AI例文
export function egDownLoad(params) {
  return request({
    params,
    url: '/v2/study/manager/eg_download',
    method: 'get'
  })
}

// 帐密登录
export function englishLogin(data) {
  return request({
    data,
    method: 'post',
    url: '/v2/study/manager/english_login'
  })
}
// 英语用户注册
export function englishSignup(data) {
  return request({
    data,
    method: 'post',
    url: '/v2/study/manager/english_signup'
  })
}
// 英语用户忘记密码
export function forgotPassword(data) {
  return request({
    data,
    method: 'post',
    url: '/v2/study/manager/forgot_password'
  })
}
// 英语用户验证码登录
export function codeLogin(data) {
  return request({
    data,
    method: 'post',
    url: '/v2/study/manager/code_login'
  })
}
// 查询个人信息
export function getEnUserInfo(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/get_user_info'
  })
}
// 修改个人信息
export function editEnUserInfo(params, data) {
  return request({
    params,
    data,
    method: 'post',
    url: '/v2/study/manager/edit_user'
  })
}
// 管理员更新教师信息
export function adminEditEnInfo(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/edit_teacher_admin'
  })
}
// 查询学校教师列表
export function findTecher(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/find_teacher'
  })
}
// 新增教师
export function addTecher(params, data) {
  return request({
    params,
    data,
    method: 'post',
    url: '/v2/study/manager/add_teacher'
  })
}
// 删除教师
export function delTecher(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/del_teacher'
  })
}
// 批量导入教师
export function importTeacherList(params, data) {
  let formData = new FormData();
  console.log(data)
  formData.append('file', data[0].raw);
  return request({
    params,
    data: formData,
    method: 'post',
    url: '/v2/study/manager/import_teacher_list'
  })
}

// 查询教师移交班级参数
export function classParameters(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/class_parameters'
  })
}
// 移交班级
export function handOver(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/hand_over'
  })
}
// 修改班级信息
export function editClass(params, data) {
  return request({
    params,
    data,
    method: 'post',
    url: '/v2/study/manager/edit_class'
  })
}
// 查询某班学生列表
export function studentList(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/student_list'
  })
}
// 老师修改学生信息
export function teDitStudent(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/t_edit_student'
  })
}
// 删除班级
export function delClass(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/del_class'
  })
}
// 创建班级
export function createClass(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/create_class'
  })
}

// 教师查询班级列表
export function classList(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/class_list'
  })
}
// 上传本地图片存远程
export function pushImage(params, data) {
  return request({
    url: '/v2/interact/get/push_image',
    params,
    data,
    method: 'post'
  })
}
// 删除学生
export function delStudent(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/del_student'
  })
}
// 导入班级成员
export function importClass(params, data) {
  let formData = new FormData();
  formData.append('file', data[0].raw);
  return request({
    params,
    data: formData,
    method: 'post',
    url: '/v2/study/manager/import_class'
  })
}
// 加入班级
export function joinClass(params, data) {
  return request({
    params,
    data,
    method: 'post',
    url: '/v2/study/manager/join_class'
  })
}
// 答题文档历史记录
export function answerRecord(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/answer_record'
  })
}
// 英语平台每日数据
export function classDay(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/class_day'
  })
}
// 英语平台分类数据
export function categorizeDailyData(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/Categorize_daily_data'
  })
}
// 分类排行榜
export function rank(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/rank'
  })
}
// 趋势分析
export function topData(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/top_data'
  })
}
// 用户的排行榜
export function userRank(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/rank_user'
  })
}
// 英语平台分类数据（用户）
export function userCategorizeDailyData(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/Categorize_daily_user'
  })
}
// 英语平台每日数据（用户）
export function dailyDataUser(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/daily_data_user'
  })
}
// 分类排行榜（用户）
export function classRank(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/class_rank'
  })
}
// 班级大概分数统计（教师）
export function scoreCount(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/score_count'
  })
}
// 教师查询题目列表
export function listTopics(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/list_topics'
  })
}
// 教师选择某题目进行解答同步
export function choosesTopics(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/chooses_topic'
  })
}
// 关于AI的配置
export function aiConfig(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/ai_config'
  })
}
// 知识图谱
export function taskList(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/task_list'
  })
}
// 查询已经开通模块
export function moduleList(params) {
  return request({
    params,
    method: 'post',
    url: '/v2/study/manager/model_list'
  })
}
// 查询任务下的提目
export function aisQuestion(params) {
  return request({
    params,
    method: 'get',
    url: '/v2/study/manager/ais_question'
  })
}
// 事件查询
export function aiTipsList(params) {
  return request({
    method: 'post',
    params,
    url: '/v2/study/robot/ai_tips_list'
  })
}
// 事件参数列表
export function aisParams(params) {
  console.log("aisParams",params)
  return request({
    method: 'post',
    params,
    url: '/v2/study/robot/ais_params'
  })
}
// 设置url全体参数
export function setUrlParams(params) {
  if(!params.method) return
  return request(params)
}

//文章评测
export function articleEvaluate(data) {
  console.log(data)
  let { file, language, category, group, ise_text, token, id_okwords, title, _id } = data
  let formData = new FormData();
  formData.append("file", file);
  formData.append("ise_text", ise_text);
  return request({
    url: '/v2/media/xf/audio/ise_file',
    method: 'post',
    params: {
      language, category, group, token, id_okwords, title, _id
    },
    data: formData
  })
}

// 图片ocr识别
export function imageOCR(params, data) {
  return request({
      params, data,
      method: 'post',
      url: '/v2/prompt/image_ocr'
  })
}
// 英文相似问题
export function getSimilarEnglish(data) {
  // console.log("data:????",data)
  return request({
    url: '/v2/dm/chat/similar/question/english',
    method: 'get',
    params: data
  })
}