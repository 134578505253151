import axios from 'axios'
import $store from "@/store/index"
import Vue from 'vue'
import router from '@/router'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 400000
})
request.interceptors.request.use(
  config => {
    let token = $store.state.token
    if (token) {
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  response => {
    const res = response.data
    if (res && res.code == '301') {
      // if (res && res.code == '301' && (response.config.url.includes('token') || response.config.url.includes('get_user_info'))) {
      // $store.commit('SET_TOKEN', '')
      // $store.commit('setUserInfo', {})
      console.log('token过期，request退出登录', response.config.url, res)
      $store.dispatch('loginOut')
      router.push('/loginIndex')
      return res
    } else {
      return res
    }
  },
  error => {
    return Promise.reject(error)
  }
)
export default request