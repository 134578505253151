import request from '@/utils/request'
// 获取验证码
export function getSmscode(data) {
    console.log(data)
    return request({
        url: '/v2/user/send_code',
        //url: '/v1/user/Sphonecode',
        method: 'post',
        data
    })
}
// 生成小程序码
export function QRcpdeGet(params) {
    return request({
        url: '/v2/user/QR_code_get',
        params,
        method: 'get'
    })
}
// 轮询登录
export function pollLogin(params) {
    return request({
        url: '/v2/user/poll_login',
        params,
        method: 'get'
    })
}
// 验证token是否过期
export function authToken(params){
    return request({
        url:'/v2/admin/other/token',
        params,
        method:'get'
    })
}
// 查询所有学校信息
export function getSchoolList() {
    return request({
        url: '/v2/study/manager/ais_school_list',
        method: 'get'
    })
}
// 修改用户头像
export function updateAvatar(data) {
    let formObj = new FormData()
    formObj.append('file', data.file)
    formObj.append('token', data.tk)
    console.log("修改的头像数据", formObj)

    let params = { token: data.tk }
    if(data.come){
        params.come=data.come
    }
    return request({
        url: '/v2/user/setavatar',
        method: 'post',
        data: formObj,
        params
    })
}